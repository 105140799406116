<template>
  <div class="w100 wtm_statistics_height bg-fff pl-20 pr-20 pt-20 pb-20 autobox">
    <div class="w100 dp-f fw-w">
      <div class="input_box w-250 mb-24">
        <div class="input_title">实名状态：</div>
        <el-select v-model="state.searchKey.status" class="input" size="large">
          <el-option v-for="item in state.options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="input_box w-250 mb-24">
        <div class="input_title">所属部门：</div>
        <el-select v-model="state.searchKey.dept" class="input" size="large">
          <el-option v-for="item in state.optionsType" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="input_box w-400 mb-24">
        <div class="input_title">选择日期：</div>
        <el-date-picker v-model="state.searchKey.dateTime" type="datetimerange" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"/>
      </div>
      <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
        @buttonclick="getDataList"></oabutton>
    </div>
    <statistics class="mb-40" :datas="state.statisticsData"></statistics>
    <el-table :data="state.Notice" class="mb-20 table"
      :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
      row-key="id">
      <el-table-column :align="'center'" prop="chnname" label="姓名" />
      <el-table-column :align="'center'" prop="deptName" label="部门" />
      <el-table-column :align="'center'" label="实名状态">
        <!-- isCertification -->
        <template #default="scope">
          <div>
            {{ scope.row.isCertification ? "认证" : '未认证' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :align="'center'" prop="createDate" label="创建时间" />
      <el-table-column :align="'center'" prop="certificationTime" label="实名时间" />
    </el-table>
    <el-pagination class="dp-f jc-c" @current-change="handleCurrentChange" background layout="prev, pager, next,slot"
      :current-page="currentPage" :total="totalPage">
    </el-pagination>
  </div>
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
import { getcontractStatus } from "@/utils/server/getcode.js"
import statistics from "../components/statistics.vue"
import { httpToken } from "@/utils/request";
import { getdept } from '@/utils/server/selectdata'
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
const loading = ref(false);//loading 显示
const countLoading = ref(false)
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const state = reactive({
  searchKey: {
    status: '',//实名状态
    dept: '',//所属部门
    dateTime: null//时间
  },//搜索绑定值
  options: [{ label: '全部', value: null },{ label: '已认证', value: '1' }, { label: '未认证', value: '0' }],//下拉数据
  optionsType: [],
  Notice: [
    { name2: '', status: 1 },
  ],//表格数据
  statisticsData: [
    { title: '团队成员', count: 0, icon: 'img1.png' },
    { title: '已实名', count: 0, icon: 'img6.png' },
    { title: '未实名', count: 10, icon: 'img9.png' },
  ],//统计数据
})
getdept().then(res => {
  state.optionsType = res
})
const getCountData = (type) => {
  countLoading.value = true;
  httpToken({
    url: "/initiate-contract/contract/certificationCount",
    method: "POST",
  }).then((res) => {
      // certificationCount
      // noCertificationCount
      // yesCertificationCount
      state.statisticsData[0].count = res.data.certificationCount;
      state.statisticsData[1].count = res.data.yesCertificationCount;
      state.statisticsData[2].count = res.data.noCertificationCount;
      console.log(res);
      countLoading.value = false;
    })
    .catch((err) => {
      countLoading.value = false;
    });
};
getCountData();
//获取列表数据
const getDataList = (val = 1) => {
  state.Notice = [];
  currentPage.value = val;
  loading.value = true;
  queryResourceTable(
    "/initiate-contract/contract/userList",
    qs.stringify({
      page: val,
      status: state.searchKey.status,
      dept: state.searchKey.dept?String(state.searchKey.dept):'',
      dateTime: JSON.stringify(state.searchKey.dateTime),
    })
  )
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (val !== res.data.current) {
        getDataList(res.data.current);
        return;
      }
      totalPage.value = res.data.total;
      state.Notice = res.data.records;
      loading.value = false;
    })
    .catch((err) => {
      if (err.message != "stop") loading.value = false;
    });
};
const handleCurrentChange = ((val) => {
  getDataList(val)
})
getDataList()
</script>
<style lang="scss" scoped >
// 重新定义最小高度
.wtm_height {
  min-height: calc(100vh - 148px - 46px);
}

::v-deep .Status {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

::v-deep .Status1 {
  color: #FF4D4F;
}

.table {
  min-height: 350px;
}
</style>